// Variables

$icon-header-color: #1B2746;
$icon-yellow-color: #FFD400;

// Utility classes
.icon-header {
  color: $icon-header-color;
}

.icon-med {
  color: $icon-yellow-color;
}