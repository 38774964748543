// Variables
$background-header-color: #FFD400;
$display-block: block;
$half-rem: 0.5rem;
$user-header-color: #FDF4C9;

// Utility classes
.header-med {
  background: $background-header-color!important;
}

.d-block {
  display: $display-block;
}

.user-header {
  background-color: $user-header-color!important;
}