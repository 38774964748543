// Variables
$med-red-color: #F1416C;
$med-green-color: #50CD89;
$med-gray-color: #C4C4C4;
$med-light-green-color: #DBF7E8;
$white-color: #FFFFFF;
$black-color: #000000;
$med-background-gray: #F8F8F8;
$med-black-color: #4A4B68;
$metronic-primary: #009ef7;
$med-symbol-bg-color: #BCBCBA;
$med-bg-light-warning: #FCEBE4;
$med-light-primary: #E3F1FF;
$med-lighter-warning: #FDF6F3;
$med-light-yellow: #FEF9E5;
$med-border-secondary: #888C9F;
$med-primary-bold-color: #167eb9;
$med-success-color: #1BC5BD;
$med-purple-color: #1B2746;
$med-red-color: rgba(255, 87, 117, 1);
$med-medium-red-color: rgba(245, 171, 184, 1);
$med-light-red-color: rgba(255, 215, 222, 1);
$med-light-purple-color: #7481AE;
$med-orange-color: rgba(255, 138, 30, 1);
$med-medium-orange-color: rgba(231, 160, 109, 1);
$med-light-orange-color: rgba(255, 138, 30, 0.32);
$med-light-dark-color: rgba(0, 0, 0, 0.2);
$med-light-danger-color: rgba(228, 51, 50, 0.2);



.med-danger {
  color: $med-red-color;
}

.med-dark {
  color: $med-black-color!important;
}

.med-color-orange{
  color: $med-orange-color!important;
}

.med-color-purple{
  color: $med-purple-color!important;
}

.med-color-red{
  color: $med-red-color!important;
}

.bg-med-success {
  background-color: $med-success-color!important;
}

.bg-med-danger {
  background-color: $med-light-danger-color!important;
}

.bg-med-purple {
  background-color: $med-purple-color!important;
}

.bg-med-light-purple {
  background-color: $med-light-purple-color!important;
}

.bg-med-red {
  background-color: $med-red-color!important;
}

.bg-med-medium-red {
  background-color: $med-medium-red-color!important;
}

.bg-med-light-red {
  background-color: $med-light-red-color!important;
}

.bg-med-light-dark {
  background-color: $med-light-dark-color;
}

.bg-med-orange {
  background-color: $med-orange-color;
}

.bg-med-medium-orange {
  background-color: $med-medium-orange-color;
}

.bg-med-light-orange {
  background-color: $med-light-orange-color;
}

.border-bottom-danger {
  border-bottom: solid $med-red-color;
}

.border-bottom-success {
  border-bottom: solid $med-green-color;
}

.border-bottom-light {
  border-bottom: solid $med-gray-color;
}

.custom-z-index {
  z-index: 1055;
}
.accordion-button:not(.collapsed) {
  color: $black-color;
  background-color: $white-color;
}

.accordion-button {
  padding: 0;
}

.accordion-permissions.accordion-body {
  background: $med-background-gray;
  border-left: solid 3px #E4E6EF;
  padding: 0.5rem 0.5rem 0;
}

.fw-400 {
  font-weight: 400!important;
}

.fw-500 {
  font-weight: 500!important;
}

.fw-600 {
  font-weight: 600!important;
}

.fs-7 {
  font-size: 0.85rem!important;
}

.p-1-25 {
  padding: 0.4rem 2.25rem!important;
}
.accordion-profile > .accordion-button::before {
  width: 1.80rem;
  height: 1.80rem;
  margin-right: 1rem;
  content: "";
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20opacity%3D%220.3%22%20x%3D%222%22%20y%3D%222%22%20width%3D%2220%22%20height%3D%2220%22%20rx%3D%225%22%20fill%3D%22%235E6278%22%2F%3E%0A%3Crect%20x%3D%2210.8891%22%20y%3D%2217.8033%22%20width%3D%2212%22%20height%3D%222%22%20rx%3D%221%22%20transform%3D%22rotate%28-90%2010.8891%2017.8033%29%22%20fill%3D%22%235E6278%22%2F%3E%0A%3Crect%20x%3D%226.01041%22%20y%3D%2210.9247%22%20width%3D%2212%22%20height%3D%222%22%20rx%3D%221%22%20fill%3D%22%235E6278%22%2F%3E%0A%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-size: 1.80rem;
  transition: transform 0.2s ease-in-out;
}

.accordion-profile > .accordion-button:not(.collapsed)::before {
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20opacity%3D%220.3%22%20x%3D%222%22%20y%3D%222%22%20width%3D%2220%22%20height%3D%2220%22%20rx%3D%225%22%20fill%3D%22%235E6278%22%2F%3E%0A%3Crect%20x%3D%226.0104%22%20y%3D%2210.9247%22%20width%3D%2212%22%20height%3D%222%22%20rx%3D%221%22%20fill%3D%22%235E6278%22%2F%3E%0A%3C%2Fsvg%3E%0A');
}

.accordion-profile > .accordion-button::after {
  display: none;
}

.accordion-profile > .accordion-button:not(.collapsed)::after {
  display: none;
}

.accordion-button:not(.collapsed) {
  box-shadow: inset 0 0px 0 #eff2f5;
}

.btn-light-primary:hover {
  background: $metronic-primary;
}

.w-32px {
  width: 32px!important;
}

.h-32px {
  height: 32px!important;
}

.h-36px {
  height: 36px!important;
}

.pt-half {
  padding-top: 0.15rem;
}

.page-item:hover:not(.offset) {
  &.active {
    .page-link {
      color: #ffffff;
    }
  }
}

.bg-med-grey {
  background-color: $med-symbol-bg-color!important;
}

#kt_modal_create {
  .modal-content {
    background-color: transparent;
  }
}

.mw-1200px {
  max-width:1200px!important
}

.min-height-50px {
  min-height:50px!important
}

.bg-med-light-warning {
  background-color: $med-bg-light-warning!important;
}

.fs-max {
  font-size: 2.5rem!important;
}

.bg-med-light-primary {
  background-color: $med-light-primary!important;
}

.bg-med-lighter-primary {
  background-color: $med-lighter-warning!important;
}

.min-height-form-browser{
  min-height: 500px!important;
}

.min-height-form-mobile{
  min-height: 600px!important;
}
.bg-med-light-yellow {
  background-color: $med-light-yellow!important;
}
.bg-med-green {
  background-color: $med-green-color!important;
}

.bg-med-light-green {
  background-color: $med-light-green-color!important;
}

.h-46px {
  height: 46px!important;
}

.w-max-content {
  width: max-content;
}

.med-object-fit {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.med-border-secondary {
  border-color: $med-border-secondary!important;
  border-width: 2px;
  opacity: 0.5;
}

.med-bold-primary {
  background-color: $med-primary-bold-color!important;
}

// Input Range in active items
.input-range-dark[type='range'] {
  overflow: hidden;
  width: 110px;
  -webkit-appearance: none;
  background-color: #1B2746;
  border-radius: 5px;
}

.input-range-dark[type='range']::-webkit-slider-runnable-track {
  height: 5px;
  -webkit-appearance: none;
  color: white;
}

.input-range-dark[type='range']::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 10px;
  cursor: ew-resize;
  background: white;
  box-shadow: -80px 0 0 80px white;
}

// Input Range in normal items
.input-range-secondary[type='range'] {
  overflow: hidden;
  width: 110px;
  -webkit-appearance: none;
  background-color: #C9C8C8;
  border-radius: 5px;
}

.input-range-secondary[type='range']::-webkit-slider-runnable-track {
  height: 5px;
  -webkit-appearance: none;
  color: #C9C8C8;
}

.input-range-secondary[type='range']::-webkit-slider-thumb {
  display: none
}

// Input Range in green
.input-range-success[type='range'] {
  overflow: hidden;
  width: 110px;
  -webkit-appearance: none;
  background-color: #7C878E;
  border-radius: 5px;
}

.input-range-success[type='range']::-webkit-slider-runnable-track {
  height: 5px;
  -webkit-appearance: none;
  color: white;
}

.input-range-success[type='range']::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 10px;
  cursor: ew-resize;
  background: #1BC47D;
  box-shadow: -80px 0 0 80px #1BC47D;
}

// Input Range in white
.input-range-white[type='range'] {
  overflow: hidden;
  width: 110px;
  -webkit-appearance: none;
  background-color: #7C878E;
  border-radius: 5px;
}

.input-range-white[type='range']::-webkit-slider-runnable-track {
  height: 5px;
  -webkit-appearance: none;
  color: white;
}

.input-range-white[type='range']::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 10px;
  cursor: ew-resize;
  background: white;
  box-shadow: -80px 0 0 80px white;
}

// Input Range in active items
.input-range-support[type='range'] {
  overflow: hidden;
  width: 110px;
  -webkit-appearance: none;
  background-color: #7C878E;
  border-radius: 5px;
}

.input-range-support[type='range']::-webkit-slider-runnable-track {
  height: 5px;
  -webkit-appearance: none;
  color: #1B2746;
}

.input-range-support[type='range']::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 10px;
  cursor: ew-resize;
  background: #1B2746;
  box-shadow: -80px 0 0 80px #1B2746;
}

.triangle:before {
  content: '';
  display: block;
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-style: solid;
}

.triangle:before {
  margin: 0;
  top: 40%;
  border-color: transparent transparent transparent $med-success-color;
  border-width: 11px;
}

.form-check-med-custom {
  display: flex;
  align-items: center;


  &.form-check-secondary {
    .form-check-input {
      &:checked {
        background-color: #9FA6B2;
        border-color: #9FA6B2;
      }
    }
  }
}


